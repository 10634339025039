import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { AiFillCheckCircle } from "react-icons/ai"

const ProductSafeRtos = () => (
  <Layout>
    <Seo title="SafeRTOS" />
    <main>
      {/* TITLE */}
      <section>
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">SAFERTOS</h1>
                <p className="lead fw-normal text-muted mb-4">
                  機能安全認証リアルタイムOS
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* MAIN */}
      <section className="bg-dark">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="row py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_safertos/safertos_main.svg"
                  alt="stm32f750-discovery-1"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1
                  className="py-lg-5 text-center text-lg-start"
                  style={{
                    color: "#fff",
                    textShadow: "0 0 20px rgba(255,255,255,0.5)",
                  }}
                >
                  IEC 61508-3 SIL 3
                  <br />
                  ISO 26262 ASIL D
                  <br />
                  事前認証済み
                  <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature1 */}
      <section className="py-lg-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder text-gradient">充実したOS機能</h2>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5 row-cols-1 row-cols-md-2">
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;機能
                  </h2>
                  <ul>
                    <li>ハードリアルタイムOS</li>
                    <li>ラウンドロビンスケジューリング採用</li>
                    <li>タスク数無制限</li>
                    <li>セマフォ、ミューテックス</li>
                    <li>タスク通知、イベントフラグ</li>
                    <li>ソフトタイマ</li>
                    <li>低消費電力モード(Low Power mode)</li>
                  </ul>
                </div>
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;Compact Footprint
                  </h2>
                  <ul>
                    <li>ROM使用量: 16 KByte</li>
                    <li>RAM使用量: 1 KByte</li>
                    <li>タスクあたりのスタック容量: 400 Byte</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature2 */}
      <section className="py-lg-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder text-gradient">Design Assurance Pack</h2>
            </div>
            <div className="col-lg-8">
              Design Assurance
              Pack(DAP)は、SAFERTOSの設計、デザイン、安全性、テストプロシージャ、テスト結果を含めた各種ドキュメントのことを指します。
              <br />
              認証取得の手続きに要する時間を大幅に短縮することができます。
              <br />
              <br />
              以下のドキュメントが含まれています。
              <br />
              <br />
              <strong>Manual</strong>
              <ul>
                <li>SAFERTOS User Manual</li>
                <li>SAFERTOS Safety Manual</li>
                <li>Upgrading from FreeRTOS Kernel to SAFERTOS</li>
                <li>Using the SAFERTOS Demo</li>
              </ul>
              <strong>Configuration</strong>
              <ul>
                <li>Software Version Description</li>
              </ul>
              <strong>Planning</strong>
              <ul>
                <li>Software Development Plan</li>
                <li>Software Test Plan</li>
                <li>Software Configuration Management Plan</li>
                <li>Software Safety Management Plan</li>
              </ul>
              <strong>Requirements</strong>
              <ul>
                <li>Customer Requirements Specification</li>
                <li>Software Requirements Specification</li>
                <li>MPU Customer Requirements Specification</li>
                <li>MPU Software Requirements Specification</li>
                <li>FPU Customer Requirements Specification</li>
                <li>FPU Software Requirements Specification</li>
              </ul>
              <strong>Design</strong>
              <ul>
                <li>Architectural Software Design Description</li>
                <li>Architectural Software Design Description for FPU</li>
                <li>Architectural Software Design Description for MPU</li>
                <li>Detailed Software Design Description</li>
                <li>Product Specific Software Design Description</li>
              </ul>
              <strong>Safety</strong>
              <ul>
                <li>HAZOPS Report</li>
                <li>HAZOPS Report for MPU Requirements</li>
                <li>Investigations arising from HAZOPS</li>
                <li>API Usage Safety Review</li>
                <li>MPU API Usage Safety Review</li>
                <li>Evidence Supporting IEC61508-3 SIL3 Claim</li>
              </ul>
              <strong>Test Procedures</strong>
              <ul>
                <li>Software Code Verification Test Description</li>
                <li>Software Integration Verification Test Description</li>
                <li>Software System Verification Test Description</li>
                <li>Validation Test Description</li>
                <li>
                  Product Variant Software Code Verification Test Description
                </li>
                <li>
                  Product Variant Software Integration Verification Test
                  Description
                </li>
                <li>System Verification Test Description</li>
                <li>Test Harness Build Procedure</li>
                <li>SAFERTOS Test Harness for your toolchain and compiler</li>
              </ul>
              <strong>Test Results</strong>
              <ul>
                <li>Software Test Report</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h1 className="fw-bolder">よくある質問</h1>
          </div>
          <div className="row gx-5">
            <div className="col-xl-8">
              {/* <h2 className="fw-bolder mb-3">ライセンス関連</h2> */}
              <div className="accordion mb-5" id="accordionExample">
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Q. ライセンスモデルについて教えてください。
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse show"
                    id="collapseOne"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      以下のライセンスを用意しております。
                      <ul>
                        <li>
                          Product License:
                          一つのプロダクトに対して複数製品開発に使用
                        </li>
                        <li>Multi Product License: 複数の製品開発に使用</li>
                        <li>
                          Corporate License : お客様のすべての製品開発に使用
                        </li>
                      </ul>
                      <strong>共通事項</strong>
                      <ul>
                        <li type="circle">
                          ライセンス契約の際に指定されたプロセッサとコンパイラが変わる場合は、新規ライセンスの契約が必要です。
                        </li>
                        <li type="circle">
                          ランニングロイヤリティは発生しません。
                        </li>
                        <li type="circle">
                          量産ライセンス費用及び数量制限もございません。
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Q. ライセンス費用について教えてください。
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="collapseTwo"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      対象となるMCUまたはMPUによりライセンス費用が変動します。
                      <br />
                      メールにてお問い合わせください。
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Q. 評価版はありますか。
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="collapseThree"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      はい。あります。評価版は下記リンクから入手できます。
                      <br />
                      <a
                        href="https://www.highintegritysystems.com/down-loads/manuals-datasheets/safertos-datasheet-downloads/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        SAFERTOS Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card border-0 bg-light mt-xl-5">
                <div className="card-body p-4 py-lg-5">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-left">
                      <div className="h4">
                        製品に関するお問い合わせは
                        <br />
                        メールにてご相談ください。
                      </div>
                      <p className="text-muted mb-4">
                        <a href="mailto:dioiz@dioiz.com?subject=Embedded Wizardに関するお問い合わせ&amp;cc=kmchoi@dioiz.com">
                          dioiz@dioiz.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default ProductSafeRtos
